//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { devices } from "~/helpers";

export default {
    name: "BowLocomotiveScroll",

    props: {
        sections: {
            default: null,
            type: Array,
        },
    },

    data: () => ({}),

    mounted() {
        this.locomotive = this.$refs.scroller.locomotive;

        this.observeWrapper = new ResizeObserver(() => {
            this.$nuxt.$emit("update-locomotive");
        });

        this.observeWrapper.observe(
            document.querySelector(".b-scroll__wrapper")
        );

        this.locomotive.on("call", (value, way, obj) => {
            switch (value) {
                case "cta": {
                    this.cta(way, obj);
                    break;
                }

                case "fade": {
                    this.fade(way, obj);
                    break;
                }

                default:
                    break;
            }
        });
    },

    methods: {
        cta(way, obj) {
            if (devices.isDesktop === false) {
                if (way === "enter") {
                    obj.target.classList.add("after:w-full");
                } else {
                    obj.target.classList.remove("after:w-full");
                }
            } else {
                obj.target.classList.remove("after:w-full");
            }
        },

        fade(way, obj) {
            if (way === "enter") {
                this.$anime({
                    targets: obj.target,
                    opacity: [0, 1],
                    duration: 3000,
                });
            } else {
                this.$anime({
                    targets: obj.target,
                    opacity: [1, 0],
                    duration: 3000,
                });
            }
        },
    },
};
