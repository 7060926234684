import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d3fad30e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowHeader: require('/workspace/components/organisms/BowHeader/BowHeader.vue').default,BowLocomotiveScroll: require('/workspace/components/base/BowLocomotiveScroll/BowLocomotiveScroll.vue').default,BowFooter: require('/workspace/components/organisms/BowFooter/BowFooter.vue').default,BowSliderFull: require('/workspace/components/molecules/BowSlider/BowSliderFull.vue').default})
