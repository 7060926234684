var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'm-slider-full',
        'fixed left-0 h-screen top-0 w-screen z-50',
        _vm.showFullSlider === true ? '' : 'opacity-0 pointer-events-none' ],attrs:{"data-current":_vm.moveSlider}},[_c('div',{staticClass:"bg-black/80 absolute top-0 left-0 w-full h-full"}),_vm._v(" "),_c('div',{ref:"slider-container",class:[
            'm-slider__container h-full relative w-full swiper js-slider' ]},[_c('div',{class:[
                'absolute bg-white rounded-full cursor-pointer h-11 right-6 top-6 w-11 z-10',
                'flex flex-col items-center justify-center duration-300 hover:scale-75' ],on:{"click":_vm.handleClose}},[_c('BowIcons',{staticClass:"text-xl text-center text-secondary",attrs:{"icon":"bi bi-x-lg"}})],1),_vm._v(" "),_c('div',{class:[
                'm-slider__wrapper swiper-wrapper h-full w-full',
                _vm.pagination !== false ? 'pb-12' : '' ]},_vm._l((_vm.slides),function(img,i){return _c('BowSlide',{key:("slide-" + i)},[_c('BowImage',{attrs:{"classPicture":"h-full w-full","classImage":"h-full object-contain w-full lg:object-cover","image":img.image,"radius":false}})],1)}),1),_vm._v(" "),(_vm.pagination !== false)?_c('div',{ref:"slider-pagination",staticClass:"swiper-pagination"}):_vm._e(),_vm._v(" "),(_vm.navigation !== false)?_c('div',{class:[
                'm-slider__navigation hidden lg:block lg:w-full',
                _vm.showFullSlider === true || _vm.isActive === true
                    ? ''
                    : 'opacity-0 pointer-events-none' ]},[_c('div',{ref:"slider-prev",class:[
                    'swiper-button-prev',
                    'absolute bg-white top-1/2 -translate-y-1/2 left-6',
                    'text-secondary transform h-12 p-2 rounded-full w-12 z-1',
                    'after:hidden shadow-md' ]},[_c('BowIcons',{staticClass:"text-3xl text-center text-secondary",attrs:{"icon":"bi bi-chevron-left"}})],1),_vm._v(" "),_c('div',{ref:"slider-next",class:[
                    'swiper-button-next',
                    'absolute bg-white top-1/2 -translate-y-1/2 right-6',
                    'text-secondary transform h-12 p-2 rounded-full w-12 z-1',
                    'after:hidden shadow-md' ]},[_c('BowIcons',{staticClass:"text-3xl text-center text-secondary",attrs:{"icon":"bi bi-chevron-right"}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }