import { render, staticRenderFns } from "./BowSliderFull.vue?vue&type=template&id=118d7df6&"
import script from "./BowSliderFull.vue?vue&type=script&lang=js&"
export * from "./BowSliderFull.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowIcons: require('/workspace/components/atoms/BowIcons/BowIcons.vue').default,BowImage: require('/workspace/components/atoms/BowImage/BowImage.vue').default,BowSlide: require('/workspace/components/molecules/BowSlider/BowSlide.vue').default})
