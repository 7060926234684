//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from "vuex";

export default {
    name: "BowSliderFull",

    props: {
        breakpoints: {
            default: null,
            type: Object,
        },
        centeredSlides: {
            default: false,
            type: Boolean,
        },
        isActive: {
            default: false,
            type: Boolean,
        },
        loop: {
            default: false,
            type: Boolean,
        },
        navigation: {
            default: true,
            type: Boolean,
        },
        pagination: {
            default: false,
            type: Boolean,
        },
        slidesPerView: {
            default: 1,
            type: Number,
        },
        spaceBetween: {
            default: 16,
            type: Number,
        },
    },

    data: () => ({
        list: null,
        slider: null,
        sliderThumb: null,
        options: null,
    }),

    fetch() {
        const optionsList = this.fullSliderData.options;

        this.options = optionsList
            ? { ...optionsList }
            : {
                  breakpoints: null,
                  centeredSlides: false,
                  loop: false,
                  navigation: true,
                  pagination: false,
                  slidesPerView: 1,
                  spaceBetween: 16,
              };

        this.sliderThumb = this.fullSliderThumb;
    },

    computed: {
        ...mapState({
            fullSliderData: ({ ui }) => ui.fullSliderData,
            fullSliderThumb: ({ ui }) => ui.fullSliderThumb,
            showFullSlider: ({ ui }) => ui.showFullSlider,
        }),

        ...mapGetters([
            "ui/GET_FULL_SLIDER_DATA",
            "ui/GET_FULL_SLIDER_THUMB",
            "ui/GET_SHOW_FULL_SLIDER",
        ]),

        getCommonClasses() {
            return [
                "border-t-2 border-t-secondary",
                "h-[0px] rounded-sm transition-transform w-10",
            ];
        },

        moveSlider() {
            if (this.slider && this.fullSliderThumb) {
                this.slider.slideToLoop(this.fullSliderThumb, 0, true);
            }

            return this.fullSliderThumb;
        },

        slides() {
            const list = this.fullSliderData.slides;

            if (this.slider) {
                this.slider.update();
            }

            return list;
        },
    },

    mounted() {
        const sliderContainer = this.$el.querySelector(".m-slider__container");

        this.slider = new this.$swiper(
            sliderContainer,
            // ? sliderContainer : this.$refs['slider-container'],
            {
                allowSlideNext: true,
                allowSlidePrev: true,
                // breakpoints: this.breakpoints || this.options.breakpoints,
                // centeredSlides: this.centeredSlides || this.options.centeredSlides,
                // loop: this.loop || this.options.loop,
                navigation:
                    this.navigation !== false
                        ? {
                              prevEl: this.$el.querySelector(
                                  ".swiper-button-prev"
                              ), // this.$refs['slider-prev'],
                              nextEl: this.$el.querySelector(
                                  ".swiper-button-next"
                              ), // this.$refs['slider-next'],
                          }
                        : false,
                pagination:
                    this.pagination !== false
                        ? {
                              el: this.$refs["slider-pagination"],
                              type: "bullets",
                          }
                        : false,
                // slidesPerView: this.slidesPerView || this.options.slidesPerView,
                // spaceBetween: this.spaceBetween || this.options.spaceBetween,

                breakpoints: null,
                centeredSlides: true,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
            }
        );

        this.slider.on("realIndexChange", (swiper) => {
            this.$store.dispatch(
                "ui/UPDATE_FULL_SLIDER_THUMB",
                swiper.realIndex
            );
            // console.log(swiper.realIndex);
        });
    },

    methods: {
        ...mapActions(["ui/UPDATE_FULL_SLIDER_THUMB"]),

        handleClose() {
            this.$store.dispatch("ui/UPDATE_SHOW_FULL_SLIDER", false);
        },
    },
};
