var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LocomotiveScroll',{ref:"scroller",attrs:{"getted-options":{
        direction: 'vertical',
        repeat: true,
        smartphone: {
            smooth: false,
            direction: 'vertical',
        },
        smooth: true,
        tablet: {
            smooth: false,
            direction: 'vertical',
        },
    },"sections":_vm.sections}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }