//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import posts from "~/config/posts";
import { BuildData, checkBreakpoint, getSeo } from "~/helpers";

export default {
    name: "IndexPage",

    async asyncData({ $axios, store, i18n }) {
        const slug = "homepage";

        const postID = posts[slug];

        try {
            const optionsCheck = store.getters["options/CHECK_OPTIONS"];

            if (optionsCheck === false) {
                await store.dispatch("options/UPDATE_OPTIONS");
            }

            const postsLength = store.getters["posts/GET_POSTS_COUNT"];

            if (postsLength === 0) {
                await store.dispatch("posts/UPDATE_POSTS");
            }

            // Save number of sections
            const postCall = await $axios.$get(`posts/${postID}`, {
                params: {
                    culture: i18n.locale,
                },
            });

            const post =
                postCall.isSuccess === true && postCall.payload
                    ? postCall.payload.posts[0]
                    : null;

            store.dispatch("homepage/updateData", post);

            const bd = new BuildData(post, "page");
            const postData = bd.build();

            store.dispatch("ui/UPDATE_IS_PAGE_IMMOBILI", false);

            return { postData };
        } catch (e) {
            console.error(e);
        }
    },

    data: () => ({
        checkBreakpointUpXl: false,
        container: null,
        currentX: 0,
        currentY: 0,
        initialSectionCount: 0,
        post: null,
        postData: null,
        sections: [],
        seo: {
            title: null,
            description: null,
            breadcrumb_title: null,
            is_robots_noindex: null,
            is_robots_nofollow: false,
            is_robots_noarchive: null,
            is_robots_noimageindex: null,
            is_robots_nosnippet: null,
            twitter_title: null,
            twitter_image: null,
            twitter_description: null,
            twitter_image_id: null,
            twitter_image_source: null,
            open_graph_title: null,
            open_graph_description: null,
            open_graph_image: null,
            open_graph_image_id: null,
            open_graph_image_source: null,
            open_graph_image_meta: null,
        },
    }),

    fetch() {
        this.post = { ...this.$store.state.homepage.data };

        if (this.postData) {
            this.sections = this.postData.sections;

            if (this.sections) {
                // Do not update this rule in other part of code
                this.initialSectionCount = this.sections.length;
            }

            this.seo = this.postData.seo;

            this.$store.dispatch(
                "ui/UPDATE_IS_COVER_FIRST_SECTION",
                this.postData.isCoverFirstSection
            );
        }
    },

    head() {
        if (this.seo === null || typeof this.seo === "undefined") {
            return;
        }

        const baseURL = this.$config.BASE_URL || "https://www.libertyhomes.it/";

        const currentURL = `${baseURL}`;

        const headToReturn = getSeo(this.seo, currentURL, false);

        return { ...headToReturn };
    },

    computed: {
        ...mapState({
            isNavOpen: ({ ui }) => ui.isNavOpen,
        }),

        ...mapGetters(["ui/GET_IS_NAV_OPEN"]),
    },

    mounted() {
        this.checkBreakpointUpXl = checkBreakpoint.up("xl");

        this.container = this.$refs.container;
    },

    methods: {
        ...mapActions([
            "ui/UPDATE_IS_COVER_FIRST_SECTION",
            "ui/UPDATE_IS_PAGE_IMMOBILI",
        ]),
    },
};
